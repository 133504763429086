import debounce from 'lodash-es/debounce';
import axios from 'axios';

export default class UnusedStyles {
  constructor({ storageKey, targetNode, observerConfig }) {
    if (!MutationObserver) {
      return;
    }
    this.options = { storageKey, targetNode, observerConfig };
    this.startCleanUseSelect = debounce(this.cleanUseSelect, 1500);
    this.observer = new MutationObserver(this.findUseSelect.bind(this));
  }

  start() {
    const storageItem = window.localStorage.getItem(this.options.storageKey);
    const storage = JSON.parse(storageItem);
    const interval = 14 * 8.64e+7; // спустя 2 недели проверять стили заново
    if (!storage || !storage.time || storage.time < Date.now() - interval) {
      this.firstStart();
    }
    this.observer.observe(this.options.targetNode, this.options.observerConfig);
  }

  findUseSelect() {
    this.startCleanUseSelect();
  }

  cleanUseSelect() {
    const store = JSON.parse(window.localStorage.getItem(this.options.storageKey));
    const storeSelectors = store && store.selectors;
    const findSelect = [];
    const collection = window.location.host;

    storeSelectors.forEach((select, index) => {
      if (document.querySelector(select)) {
        findSelect.push(select);
        storeSelectors.splice(index, 1);
      }
    });
    if (findSelect.length > 0 && false) {
      // FIXME: Вечно обращается по этому адресу и падает в CORS, отключил эту ошибку.
      // список используемых стилей на //csscatching.herokuapp.com/collection/?q=skgelios
      axios({
        url: '//csscatching.herokuapp.com/',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        data: {
          collection,
          list: findSelect,
        },
      })
        .then(() => {
          window.localStorage.setItem(this.options.storageKey,
            JSON.stringify({ ...store, selectors: storeSelectors }));
        });
    }
  }

  async firstStart() {
    window.localStorage.setItem(this.options.storageKey,
      JSON.stringify({ time: Date.now(), selectors: await UnusedStyles.getSelectorList() }));
  }

  static async getSelectorList() {
    const selectors = [];
    const styleSheets = new Array(...document.styleSheets);
    const promises = styleSheets.map(async (styleSheet) => {
      if ((!styleSheet.href || styleSheet.href.includes(window.location.host))) {
        const cssRules = new Array(...await UnusedStyles.awaitCssRules(styleSheet));
        cssRules.forEach((rule) => {
          const { selectorText } = rule;
          if (selectorText && selectorText.length) {
            const selectorArr = selectorText.split(',');
            selectorArr.forEach((s) => {
              const select = s.trim();
              if (!select.includes(':') && !selectors.includes(select)) {
                selectors.push(select);
              }
            });
          }
        });
      }
    });
    await Promise.all(promises);
    return selectors;
  }

  static awaitCssRules(styleSheet) {
    return new Promise((resolve) => {
      const fi = setInterval(() => {
        try {
          if (styleSheet.cssRules) { // <--- MAGIC: only populated when file is loaded
            clearInterval(fi);
            resolve(styleSheet.cssRules);
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }, 100);
    });
  }
}
