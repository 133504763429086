import VeeValidate from 'vee-validate';
import debounce from 'lodash-es/debounce';
import store from '../store';
import modules, { MODULES_NAME } from '../store/modules';

const dictionary = {
  ru: {
    messages: {
      required: () => 'Поле обязательно для заполнения',
      email: () => 'Неверный формат Email',
      numeric: () => 'Введите только цифры',
      digits: (name, criteria) => `Введите не больше ${criteria} цифр`,
      confirmed: () => 'Значения полей не совпадают',
      date_between: () => 'Возраст страхователя меньше 18 лет',
      date_format: () => 'Некорректый формат даты',
      find: 'Введите из предложенных вариантов',
      activatedPolicy: () => 'Полис не найден',
      notActivated: () => 'Этот полис не может быть активирован. Проверьте правильность номера или обратитесь в службу поддержки',
    },
    custom: {
      patient_phone: {
        regex: () => 'Поле заполнено некорректно',
      },
      payer_phone: {
        regex: () => 'Поле заполнено некорректно',
      },
      payer_passnum: {
        regex: () => 'Поле заполнено некорректно',
      },
      policy_type: () => 'Поле заполнено некорректно',
    },
  },
};

// проверяем значение на соответствие из списка options
VeeValidate.Validator.extend('find',
  (value, { options }) => (
    !!options
      .find((item) => {
        if (['boolean', 'string', 'number'].includes(typeof item.value)) {
          // eslint-disable-next-line
          const findValue = item.value.toString().toLowerCase() == value.toString().toLowerCase();
          if (findValue) {
            return findValue;
          }
        } if (['boolean', 'string', 'number'].includes(typeof item.text)) {
          // eslint-disable-next-line
          const findText = item.text.toString().toLowerCase() == value.toString().toLowerCase();
          if (findText) {
            return findText;
          }
        } if (['boolean', 'string', 'number'].includes(typeof item)) {
          // eslint-disable-next-line
          return item == value;
        }
        return false;
      })
  ),
  { paramNames: ['options'] });

// проверяем активирован ли полис
const activatedPolicy = {
  handler: debounce(async (number, callback) => {
    // проверяем наличие полиса в сторе
    if (!store.state[MODULES_NAME.POLICIES]
      || !store.state[MODULES_NAME.POLICIES].insuranceProgramByNumber[number]) {
      store.lazyModuleDispatch({
        lazyModule: modules[MODULES_NAME.POLICIES],
        moduleName: MODULES_NAME.POLICIES,
        dispatchName: 'getInsuranceProgramByNumber',
        payload: number,
      })
        .then(() => {
          callback(!!store.state[MODULES_NAME.POLICIES].insuranceProgramByNumber[number]);
        })
        .catch(() => {
          callback(false);
        });
    } else {
      callback(!!store.state[MODULES_NAME.POLICIES].insuranceProgramByNumber[number]);
    }
  }, 1000),
  value: '',
};
VeeValidate.Validator.extend('activatedPolicy',
  value => new Promise((resolve) => {
    // if (activatedPolicy.value !== value) {
    activatedPolicy.handler(value, resolve);
    // }
  }));

// проверяем, можно ли активировать полис
const notActivated = {
  handler: debounce(async (number, callback) => {
    activatedPolicy.value = number;
    // проверяем наличие полиса в сторе
    store.lazyModuleDispatch({
      lazyModule: modules[MODULES_NAME.POLICIES],
      moduleName: MODULES_NAME.POLICIES,
      store,
      dispatchName: 'checkActivationAvailable',
      payload: { PolicyNumber: number },
    })
      .then(() => {
        callback(true);
      })
      .catch(() => {
        callback(false);
      });
  }, 1000),
  value: '',
};
VeeValidate.Validator.extend('notActivated',
  value => new Promise((resolve) => {
    // if (notActivated.value !== value) {
    notActivated.handler(value, resolve);
    // }
  }));

VeeValidate.configure({
  locale: 'ru',
  dictionary,
  events: 'input|blur|change',
});

export default VeeValidate;
