export const auth = import(/* webpackChunkName: "chunk-moduleAuth" */ './moduleAuth');
export const modulePolicies = import(/* webpackChunkName: "chunk-modulePolicies" */ './policies');
export const moduleTelemedicine = import(/* webpackChunkName: "chunk-moduleTelemedicine" */ './moduleTelemedicine');
export const paymentCards = import(/* webpackChunkName: "chunk-paymentCards" */ './paymentCards');
export const moduleSubscriptions = import(/* webpackChunkName: "chunk-moduleSubscriptions" */ './moduleSubscriptions');
export const moduleProducts = import(/* webpackChunkName: "chunk-moduleProducts" */ './products');
export const moduleProfile = import(/* webpackChunkName: "chunk-moduleProfile" */ './moduleProfile');

export const MODULES_NAME = {
  AUTH: 'moduleAuth',
  POLICIES: 'modulePolicies',
  TELEMEDICINE: 'moduleTelemedicine',
  PAYMENT_CARDS: 'modulePaymentCards',
  SUBSCRIPTIONS: 'moduleSubscriptions',
  PRODUCTS: 'moduleProducts',
  PROFILE: 'moduleProfile',
};

export default {
  [MODULES_NAME.AUTH]: auth,
  [MODULES_NAME.POLICIES]: modulePolicies,
  [MODULES_NAME.TELEMEDICINE]: moduleTelemedicine,
  [MODULES_NAME.PAYMENT_CARDS]: paymentCards,
  [MODULES_NAME.SUBSCRIPTIONS]: moduleSubscriptions,
  [MODULES_NAME.PRODUCTS]: moduleProducts,
  [MODULES_NAME.PROFILE]: moduleProfile,
};
