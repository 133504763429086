import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import { MODULES_NAME } from '../store/modules';

try {
  if (process.env.VUE_APP_SENTRY_KEY && process.env.VUE_APP_SENTRY_PROJECT) {
    Sentry.init({
      dsn: `https://${process.env.VUE_APP_SENTRY_KEY}@sentry.io/${process.env.VUE_APP_SENTRY_PROJECT}`,
      integrations: [new Integrations.Vue({ Vue, logErrors: true })],
      release: `gelios@${window.GELIOS.VERSION}`,
      beforeSend(event) {
        const geliosStore = JSON.parse(localStorage.getItem('gelios'));
        if (geliosStore && geliosStore[MODULES_NAME.PROFILE]) {
          Sentry.setUser(geliosStore[MODULES_NAME.PROFILE].profile);
        }
        return event;
      },
    });
  }
} catch (e) {
  console.error(e);
}

export default Sentry;
