import Vue from 'vue';
import VueRouter from 'vue-router';
import checkView from 'vue-check-view';
import VTooltip from 'v-tooltip';
import { mapGetters } from 'vuex';
import 'url-search-params-polyfill';
import VeeValidate from './modules/validation';
import store from './store';
import UnusedStyles from './modules/unusedStyles';
import './modules/loggingSentry';
import './css/main.scss';
import modules, { MODULES_NAME } from './store/modules';

import('./modules/userCity');

window.sessionDuration = 60000 * 20; // продолжительность сессии 20мин

const componentsPage = {
  about: () => import('./pages/about'),
  activate: () => import('./pages/activate'),
  agents: () => import('./pages/agents'),
  bank: () => import('./pages/bank'),
  common: () => import('./pages/common'),
  contacts: () => import('./pages/contacts'),
  corp: () => import('./pages/corp'),
  courses: () => import('./pages/courses'),
  dissolution: () => import('./pages/dissolution'),
  filial: () => import('./pages/filial'),
  'incident-wizard': () => import('./pages/incident-wizard'),
  index: () => import('./pages/index'),
  kbm: () => import('./pages/kbm'),
  'kasko-form': () => import('./pages/kasko-form'),
  validate: () => import('./pages/validate'),
  nsSport: () => import('./pages/nsSport'),
  nsOnline: () => import('./pages/nsOnline'),
  leaders: () => import('./pages/leaders'),
  mobilized: () => import('./pages/mobilized'),
  news: () => import('./pages/news'),
  'personal-index': () => import(/* webpackChunkName: "chunk-personal-index" */ './pages/personal-index'),
  rate: () => import('./pages/rate'),
  'regional-net': () => import('./pages/regional-net'),
  review: () => import('./pages/review'),
  reviews: () => import('./pages/reviews'),
  userful: () => import('./pages/userful'),
  vacancies: () => import('./pages/vacancies'),
  vpk: () => import('./pages/vpk'),
};


Vue.use(VeeValidate);

Vue.use(checkView);

Vue.use(VTooltip, {
  defaultTrigger: 'hover click',
  defaultContainer: '#app',
});
Vue.use(VueRouter);

Vue.directive('delay', {
  inserted(el) {
    el.style.transitionDelay = `${Math.floor(Math.random() * 300) + 200}ms`;
  },
});

function runMyApp() {
  window.zebrains = new Vue({
    el: '#app',
    store,
    components: {
      Page: componentsPage[document.body.getAttribute('data-page')],
    },
    computed: {
      ...mapGetters(['fontSizeFactor']),
      stylesTheme() {
        const stylesTheme = this.$store.getters.stylesTheme
          .concat(this.$store.getters.stylesImagesFilter);
        if (this.fontSizeFactor > 1) {
          stylesTheme.push('big-font-size');
        }
        return stylesTheme;
      },
    },
    watch: {
      fontSizeFactor: {
        handler(v) {
          window.document.documentElement.style.fontSize = `${this.$store.state.VisuallyImpaired.fontSize.default * v}px`;
        },
        immediate: true,
      },
    },
    beforeCreate() {
      this.$store.loadingVuexModule({
        name: MODULES_NAME.AUTH,
        lazyModule: modules[MODULES_NAME.AUTH],
      })
        .then(() => {
          if (!this.$store.state[MODULES_NAME.AUTH].token
            || Date.now() - this.$store.state[MODULES_NAME.AUTH]
              .lastActivity > window.sessionDuration) {
            this.$store.dispatch(`${MODULES_NAME.AUTH}/logOut`);
          }
          // пока открыта страница будем обновлять сессию
          window.setInterval(() => {
            this.$store.commit(`${MODULES_NAME.AUTH}/updateLastActivity`, null, { root: true });
          }, window.sessionDuration - 1000);
        });
    },
  });
  if (process.env.VUE_APP_CSSCATCHING === 'true') {
    const unusedStyles = new UnusedStyles({
      storageKey: 'unusedCSSRules',
      targetNode: window.document.documentElement,
      observerConfig: {
        attributes: true,
        attributeFilter: ['class'],
        subtree: true,
        childList: true,
      },
    });
    unusedStyles.start();
  }
}


window.addEventListener('DOMContentLoaded', () => {
  if (!window.Intl) {
    require.ensure(['intl', 'intl/locale-data/jsonp/ru.js'], (require) => {
      require('intl');
      require('intl/locale-data/jsonp/ru.js');
      runMyApp();
    });
  } else {
    runMyApp();
  }
});
